import { UPLOAD_FILE, VENDOR_ABI, VENDOR_LOREAL } from '@constants'
import { useIs3pUser } from '@hooks'
import { useAuthenticationService } from 'admin-portal-shared-services'
import { generateUUID } from '../../../service'
import { useApiService } from '../../api'

export const useUploadedFileInfo = () => {
  const auth = useAuthenticationService()
  const api = useApiService()
  const { is3pUser } = useIs3pUser()

  const uploadFile = async (file: File) => {
    const formData = new FormData()
    formData.append('file', file)
    try {
      const response = await api.post({
        url: UPLOAD_FILE,
        data: formData,
        config: {
          headers: {
            Authorization: auth.getAuthHeader(),
            country: auth.getCountryB2C(),
            requestTraceId: generateUUID(),
            email: auth.getUserEmailB2C().toLocaleLowerCase(),
            'Content-Type': 'multipart/form-data',
            vendor: is3pUser ? VENDOR_LOREAL : VENDOR_ABI,
            version: 2
          }
        }
      })

      const { id } = response.data as { id: string }
      return id
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response && error.response.data.code === 'LDS-006') {
        throw new Error('File name not supported')
      }
      throw new Error('Error uploading file')
    }
  }

  const getFileDetails = async (templateId) => {
    const headers = {
      Authorization: auth.getAuthHeader(),
      country: auth.getCountryB2C(),
      requestTraceId: generateUUID(),
      vendor: is3pUser ? 'LOREAL' : 'ABI'
    }

    const response = await api.get({
      url: `${UPLOAD_FILE}/${templateId}`,
      config: {
        headers
      }
    })

    const fileDetails = response.data
    return fileDetails
  }

  return {
    uploadFile,
    getFileDetails
  }
}
